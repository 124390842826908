* {
  box-sizing: border-box;
}

@font-face {
  font-family: Mabry;
  src: url("../mabry-medium-pro.woff2");
}

html,
body,
#root {
  font-family: "Mabry";
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #000;
  position: fixed;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 8px;
}

@media only screen and (max-width: 600px) {
  .dot {
    width: 8px;
    height: 8px;
    margin: 5px;
  }
}

.dotsContainer {
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  margin: 0 auto;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
