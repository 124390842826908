* {
  box-sizing: border-box;
}

@font-face {
  font-family: Mabry;
  src: url("./mabry-medium-pro.woff2");
}

html,
body,
#root {
  font-family: "Mabry";
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

body {
  background: #000;
  position: fixed;
}

#fader {
  background: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
  transition: 0.4s;
}

#browserWarning {
  display: block;
  background: rgb(110, 73, 163);
  border: 0;
  position: absolute;
  width: 100%;
  z-index: 50;
  color: white;
  padding: 0;

  p {
    color: rgb(217, 190, 255);
    text-align: center;
    margin: 0.3em 0 0.4em 0;

    a {
      color: white;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

.resetButton {
  display: block;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 25px;
  bottom: 25px;

  background-image: url("./assets/ui/Redo_Btn_v1.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.resetButton:hover {
  background-image: url("./assets/ui/Redo_Hover_Btn_v1.png");
  cursor: pointer;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 8px;
}

@media only screen and (max-width: 600px) {
  .dot {
    width: 8px;
    height: 8px;
    margin: 5px;
  }
}

.dotsContainer {
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  margin: 0 auto;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dots-flash {
  animation: dotsFlash ease 2s;
  //-webkit-animation: dotsFlash ease 10s;
}

@keyframes dotsFlash {
  0% {
    filter: brightness(3);
  }
  100% {
    filter: brightness(1);
  }
}
